import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ArticleCard from '@/common/interfaces/modules/ArticleCard';
import IconCalendar from '@/assets/icons/calendar.svg?inline';
import IconPinFilled from '@/assets/icons/pin-filled.svg?inline';
import IconPlus from '@/assets/icons/plus.svg?inline';
import Listen from '@/assets/icons/listen.svg?inline';
import Watch from '@/assets/icons/watch.svg?inline';
import Read from '@/assets/icons/read.svg?inline';
import Wildlife from '@/assets/icons/binoculars.svg?inline';
import DER from '@/assets/icons/article.svg?inline';
import WorldView from '@/assets/icons/globe.svg?inline';
import DailyExpeditionReports from '@/assets/icons/book.svg?inline';
import BehindTheExpedition from '@/assets/icons/compass.svg?inline';
import Culture from '@/assets/icons/culture.svg?inline';
import Video360 from '@/assets/icons/binoculars.svg?inline';
import ContentType from '@/common/interfaces/enums/ContentType';
import CategoryType from '@/common/interfaces/enums/ContentCategory';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';
import Ship from '@/assets/icons/ship.svg?inline';

import ComponentLoading from '@/components/LoadingSpinner/ComponentLevel/ComponentLoading.vue';

const ArticleCardComponentProps = Vue.extend({
  props: { card: Object as () => ArticleCard },
});

@Component({
  components: {
    IconCalendar,
    IconPinFilled,
    IconPlus,
    Listen,
    Watch,
    Read,
    Video360,
    Wildlife,
    DER,
    WorldView,
    DailyExpeditionReports,
    BehindTheExpedition,
    Culture,
    ResponsiveImage,
    Ship,
    ComponentLoading,
  },
})
export default class ArticleCardComponent extends ArticleCardComponentProps {
  //Computed property to return content type string for a dynamic icon
  get Type() {
    switch (this.card.Type) {
      case ContentType.Read:
        return 'Read';
      case ContentType.Watch:
        return 'Watch';
      case ContentType.Listen:
        return 'Listen';
      case ContentType.Video360:
        return 'Video360';
      default:
        return null;
    }
  }

  get Category() {
    switch (this.card.Category) {
      case CategoryType.WildLife:
        return {
          CategoryType: Wildlife,
          CategoryTitle: 'Wildlife & Nature',
        };
      case CategoryType.WorldView:
        return {
          CategoryType: WorldView,
          CategoryTitle: 'World View',
        };
      case CategoryType.Culture:
        return {
          CategoryType: Culture,
          CategoryTitle: 'Culture',
        };
      case CategoryType.DER:
        return {
          CategoryType: DailyExpeditionReports,
          CategoryTitle: 'Daily Expedition Reports',
        };
      case CategoryType.BehindTheExpedition:
        return {
          CategoryType: BehindTheExpedition,
          CategoryTitle: 'Behind the Expedition',
        };
    }
  }

  imageSizesLandscape: ImageSizes = {
    def: new ImageSize([720, 480]),
    sizes: {
      sm: new ImageSize([720, 480]),
      md: new ImageSize([450, 300]),
      lg: new ImageSize([570, 380]),
    },
  };

  isActive: boolean = false;
}

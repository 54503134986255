import { Component, Vue } from 'vue-property-decorator';

const ComponentLoadingProps = Vue.extend({
  props: {
    loaded: Boolean,
  },
});

@Component({})
export default class ComponentLoading extends ComponentLoadingProps {
  width = 70;
  radius = 34;

  get circleStyle() {
    if (this.loaded) {
      return { strokeDasharray: 1000, strokeDashoffset: 1000 };
    } else {
      return null;
    }
  }
}
